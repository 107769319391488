import { styled } from '@mui/material/styles'
import { useI18n, useNavigate } from '../../functions/hooks'
import { BasicModal, ButtonRound } from '.'
import { BaseText, BaseButtonWrapper } from '../../functions/themes'

const CustomSpan = styled('span')`
  color: #0066ff;
  cursor: pointer;
`
const GapWrapper = styled('div')`
  display: grid;
  gap: 20px;
`
const Block = styled('div')`
  margin: 0 30px;
`
type ContentModalForPublicProps = {
  isOpen: boolean
  close: () => void
}

export const ContentModalForPublic: React.FC<ContentModalForPublicProps> = ({
  isOpen,
  close
}) => {
  const { isJapanese } = useI18n()
  const { pushToLogin, pushToRegister } = useNavigate()

  return (
    <BasicModal
      title={
        isJapanese
          ? 'サイトを閲覧・ご利用いただくには会員登録が必要です。'
          : 'You will need to register as a member to view and use perks offered through the site.'
      }
      size="medium"
      open={isOpen}
      handleOpen={close}
      handleCloseWithIcon={close}
      contents={
        <BaseText>
          {isJapanese
            ? 'ご登録いただくと、会員様だけにお届けする限定特典や仕事にプラスになるイベント情報の閲覧、自分のお気に入りの情報を手軽に収集・閲覧できるツールをご利用いただけます。'
            : 'Once you become a member you will have access to exclusive perks and event information that may be useful in your jobs, as well as tools to easily navigate and collect information that may be of interest.'}
        </BaseText>
      }
      footer={
        <GapWrapper>
          <BaseButtonWrapper>
            <ButtonRound onClick={pushToRegister}>
              {isJapanese ? '新規会員登録' : 'New Member Registration'}
            </ButtonRound>
          </BaseButtonWrapper>
          <Block>
            {isJapanese ? (
              <BaseText>
                既にご登録済みの方は、
                <CustomSpan onClick={pushToLogin} aria-hidden="true">
                  こちら
                </CustomSpan>
                からログインをお願いします。
              </BaseText>
            ) : (
              <BaseText>
                Please log in
                <CustomSpan onClick={pushToLogin} aria-hidden="true">
                  &nbsp;here&nbsp;
                </CustomSpan>
                if you are already registered.
              </BaseText>
            )}
          </Block>
        </GapWrapper>
      }
    />
  )
}
