import { lazyRetry } from '../../functions/utilities'

const Error = lazyRetry(
  () => import(/* webpackChunkName: "en-error-page" */ '../../pages/Error')
)
const Complete = lazyRetry(
  () =>
    import(/* webpackChunkName: "en-complete-page" */ '../../pages/Complete')
)
const Voucher = lazyRetry(
  () => import(/* webpackChunkName: "en-voucher-page" */ '../../pages/Voucher')
)
const VoucherDetail = lazyRetry(
  () =>
    import(
      /* webpackChunkName: "en-voucher-detail-page" */ '../../pages/VoucherDetail'
    )
)
const Event = lazyRetry(
  () => import(/* webpackChunkName: "en-event-page" */ '../../pages/Event')
)
const EventDetail = lazyRetry(
  () =>
    import(
      /* webpackChunkName: "en-event-detail-page" */ '../../pages/EventDetail'
    )
)
const Journal = lazyRetry(
  () => import(/* webpackChunkName: "en-journal-page" */ '../../pages/Journal')
)
const JournalDetail = lazyRetry(
  () =>
    import(
      /* webpackChunkName: "en-journal-detail-page" */ '../../pages/JournalDetail'
    )
)
const Resign = lazyRetry(
  () => import(/* webpackChunkName: "en-resign-page" */ '../../pages/Resign')
)
const Mypage = lazyRetry(
  () => import(/* webpackChunkName: "en-mypage-page" */ '../../pages/Mypage')
)
const Questionnaire = lazyRetry(
  () =>
    import(
      /* webpackChunkName: "en-questionnaire-page" */ '../../pages/Questionnaire'
    )
)
const QuestionnaireDetail = lazyRetry(
  () =>
    import(
      /* webpackChunkName: "en-questionnaire-detail-page" */ '../../pages/QuestionnaireDetail'
    )
)
const QuestionnaireDetailAnswered = lazyRetry(
  () =>
    import(
      /* webpackChunkName: "en-questionnaire-detail-answered-page" */ '../../pages/QuestionnaireDetailAnswered'
    )
)
const SettingPassword = lazyRetry(
  () =>
    import(
      /* webpackChunkName: "en-setting-password-page" */ '../../pages/SettingPassword'
    )
)
const SettingEmail = lazyRetry(
  () =>
    import(
      /* webpackChunkName: "en-setting-email-page" */ '../../pages/SettingEmail'
    )
)
const Notification = lazyRetry(
  () =>
    import(
      /* webpackChunkName: "en-notification-page" */ '../../pages/Notification'
    )
)
const NotificationDetail = lazyRetry(
  () =>
    import(
      /* webpackChunkName: "en-notification-detail-page" */ '../../pages/NotificationDetail'
    )
)

export const protectedRoutes = [
  {
    path: '*',
    element: <Error />
  },
  {
    path: '/en/voucher',
    element: <Voucher />
  },
  {
    path: '/en/voucher/:voucherId',
    element: <VoucherDetail />
  },
  {
    path: '/en/journal',
    element: <Journal />
  },
  {
    path: '/en/journal/:journalId',
    element: <JournalDetail />
  },
  {
    path: '/en/event',
    element: <Event />
  },
  {
    path: '/en/event/:eventId',
    element: <EventDetail />
  },
  {
    path: '/en/event/complete',
    element: <Complete />
  },
  {
    path: '/en/questionnaire',
    element: <Questionnaire />
  },
  {
    path: '/en/questionnaire/:questionnaireId',
    element: <QuestionnaireDetail />
  },
  {
    path: '/en/questionnaire/:questionnaireId/answered',
    element: <QuestionnaireDetailAnswered />
  },
  {
    path: '/en/questionnaire/complete',
    element: <Complete />
  },
  {
    path: '/en/notification',
    element: <Notification />
  },
  {
    path: '/en/notification/:notificationId',
    element: <NotificationDetail />
  },
  {
    path: '/en/mypage',
    element: <Mypage />
  },
  {
    path: '/en/mypage/credit/complete',
    element: <Complete />
  },
  {
    path: '/en/setting/password',
    element: <SettingPassword />
  },
  {
    path: '/en/setting/password/complete',
    element: <Complete />
  },
  {
    path: '/en/setting/email',
    element: <SettingEmail />
  },
  {
    path: '/en/setting/email/complete',
    element: <Complete />
  },
  {
    path: '/en/resign',
    element: <Resign />
  }
]
