import { lazyRetry } from '../../functions/utilities'

const Error = lazyRetry(
  () => import(/* webpackChunkName: "error-page" */ '../../pages/Error')
)
const Complete = lazyRetry(
  () => import(/* webpackChunkName: "complete-page" */ '../../pages/Complete')
)
const Voucher = lazyRetry(
  () => import(/* webpackChunkName: "voucher-page" */ '../../pages/Voucher')
)
const VoucherDetail = lazyRetry(
  () =>
    import(
      /* webpackChunkName: "voucher-detail-page" */ '../../pages/VoucherDetail'
    )
)
const Event = lazyRetry(
  () => import(/* webpackChunkName: "event-page" */ '../../pages/Event')
)
const EventDetail = lazyRetry(
  () =>
    import(
      /* webpackChunkName: "event-detail-page" */ '../../pages/EventDetail'
    )
)
const Journal = lazyRetry(
  () => import(/* webpackChunkName: "journal-page" */ '../../pages/Journal')
)
const JournalDetail = lazyRetry(
  () =>
    import(
      /* webpackChunkName: "journal-detail-page" */ '../../pages/JournalDetail'
    )
)
const Resign = lazyRetry(
  () => import(/* webpackChunkName: "resign-page" */ '../../pages/Resign')
)
const Mypage = lazyRetry(
  () => import(/* webpackChunkName: "mypage-page" */ '../../pages/Mypage')
)
const Questionnaire = lazyRetry(
  () =>
    import(
      /* webpackChunkName: "questionnaire-page" */ '../../pages/Questionnaire'
    )
)
const QuestionnaireDetail = lazyRetry(
  () =>
    import(
      /* webpackChunkName: "questionnaire-detail-page" */ '../../pages/QuestionnaireDetail'
    )
)
const QuestionnaireDetailAnswered = lazyRetry(
  () =>
    import(
      /* webpackChunkName: "questionnaire-detail-answered-page" */ '../../pages/QuestionnaireDetailAnswered'
    )
)
const SettingPassword = lazyRetry(
  () =>
    import(
      /* webpackChunkName: "setting-password-page" */ '../../pages/SettingPassword'
    )
)
const SettingEmail = lazyRetry(
  () =>
    import(
      /* webpackChunkName: "setting-email-page" */ '../../pages/SettingEmail'
    )
)
const Notification = lazyRetry(
  () =>
    import(
      /* webpackChunkName: "notification-page" */ '../../pages/Notification'
    )
)
const NotificationDetail = lazyRetry(
  () =>
    import(
      /* webpackChunkName: "notification-detail-page" */ '../../pages/NotificationDetail'
    )
)

export const protectedRoutes = [
  {
    path: '*',
    element: <Error />
  },
  {
    path: '/voucher',
    element: <Voucher />
  },
  {
    path: '/voucher/:voucherId',
    element: <VoucherDetail />
  },
  {
    path: '/journal',
    element: <Journal />
  },
  {
    path: '/journal/:journalId',
    element: <JournalDetail />
  },
  {
    path: '/event',
    element: <Event />
  },
  {
    path: '/event/:eventId',
    element: <EventDetail />
  },
  {
    path: '/event/complete',
    element: <Complete />
  },
  {
    path: '/questionnaire',
    element: <Questionnaire />
  },
  {
    path: '/questionnaire/:questionnaireId',
    element: <QuestionnaireDetail />
  },
  {
    path: '/questionnaire/:questionnaireId/answered',
    element: <QuestionnaireDetailAnswered />
  },
  {
    path: '/questionnaire/complete',
    element: <Complete />
  },
  {
    path: '/notification',
    element: <Notification />
  },
  {
    path: '/notification/:notificationId',
    element: <NotificationDetail />
  },
  {
    path: '/mypage',
    element: <Mypage />
  },
  {
    path: '/mypage/credit/complete',
    element: <Complete />
  },
  {
    path: '/setting/password',
    element: <SettingPassword />
  },
  {
    path: '/setting/password/complete',
    element: <Complete />
  },
  {
    path: '/setting/email',
    element: <SettingEmail />
  },
  {
    path: '/setting/email/complete',
    element: <Complete />
  },
  {
    path: '/resign',
    element: <Resign />
  }
]
