/**
 * 全角英数字->半角英数字
 */
export const formatHalfWidth = (target: string) => {
  return target.replace(/[！-～]/g, (tmpStr) => {
    return String.fromCharCode(tmpStr.charCodeAt(0) - 0xfee0)
  })
}

/**
 * 全角空白->半角空白
 */
export const formatHalfWidthSpaces = (target: string) => {
  /*eslint-disable-next-line*/
  return target.replace(/　/g, ' ')
}

/**
 * 全角ハイフン->半角ハイフン
 */
export const formatHalfWidthHyphen = (target: string) => {
  return target.replace(/[‐－―ー]/g, '-')
}
