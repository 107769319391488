import { Paragraph } from '../../functions/types/Common'

type ContentParagraphProps = {
  handleClick: (actionName: string) => void
  paragraphs: Paragraph[]
}

export const ContentParagraph: React.FC<ContentParagraphProps> = ({
  handleClick,
  paragraphs
}) => {
  return (
    <>
      {paragraphs.map((paragraph) => (
        <p>
          {paragraph.texts.map((text) => (
            <>
              {text.action && (
                <span
                  onClick={() => handleClick(text.actionName)}
                  aria-hidden="true"
                >
                  {text.value}
                </span>
              )}
              {!text.action && text.value}
            </>
          ))}
        </p>
      ))}
    </>
  )
}
