import { axios } from '../../../utilities/axiosClient'

type SettingPasswordComplete = {
  old_password: string
  password: string
  password2: string
}

export const getSettingPasswordComplete = (params: SettingPasswordComplete) =>
  axios.post('/api/password/complete_method', params)
